.homewrapper {
    width: 100%;
}

.dashboardhomepage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;
}

.dashboardhomepagewrapper {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.welcome-kyc-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.welcome-kyc-section p {
    width: 100%;
    color: rgb(134, 146, 167);
}

.username-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.username-container h2 {
    font-size: 2.5rem;
    font-weight: 600;
    text-transform: capitalize;
    color: rgb(83, 83, 83);
}

.username-container button {
    margin-left: 20px;
    padding: 10px 30px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(95, 95, 95);
    text-transform: capitalize;
    font-family: 'Lato';
    cursor: pointer;
    transition: .3s all ease-in-out;
}

.username-container button:hover {
    background-color: var(--primary-color);
    color: black;
}

.username-container button svg {
    font-size: 1.2rem;
    margin-left: 5px;
}

.kyc-card-wrapper {
    width: 80%;
    padding-left: 1px;
    background-color: rgb(239, 181, 37);
    border-radius: 5px;
    box-shadow: 0px 0px 5px #ddd;
    margin-top: 20px;
    margin-right: auto;
}

.kyc-card {
    width: 100%;
    padding: 10px;
    background-color: white;
    border-radius: 0px 10px 10px 0px;
    margin-left: 5px;
}

.kyc-card h3 {
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat';
    color: rgb(195, 144, 16);
}

.kyc-card p {
    margin: 15px 0px 10px 0px;
    font-size: .9rem;
    color: rgb(113, 113, 113);
}

.kyc-card a {
    text-transform: capitalize;
    font-size: .8rem;
    color: rgb(177, 127, 0);
    font-weight: 500;
}

.kyc-line {
    width: 100px;
    margin-top: 3px;
    height: 2px;
    background-color: rgb(195, 144, 16);
}

.overview-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0px 50px 0px;
    flex-wrap: wrap;
    flex-direction: column;
}

.dash-btn-container {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px 20px;

}

.dash-btn {
    padding: 15px 50px;
    width: fit-content;
    border-radius: 4rem;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px;
}

.dash-btn svg {
    font-size: 1.5rem;
    margin-right: 10px;
}

.overview-card {
    width: 50%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0px;
    min-height: 100px;
    padding: 10px 20px;
}

.overview-icon-wrapper {
    display: flex;
    flex-direction: column;
}

.overview-icon-wrapper small {
    color: rgb(119, 119, 119);
    text-transform: capitalize;
    text-align: center;
    font-family: 'poppins';
    font-size: small;
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.overview-icon-container {
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--color-variant);
    box-shadow: 0px 0px 1px rgba(147, 165, 230, 0.719);
    font-size: 2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.overview-icon-container:hover {
    box-shadow: 0px 0px 10px rgba(212, 213, 214, 0.719);
}

.price-chartt-section {
    width: 100%;
    height: auto;
}

.referral-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0px;
}

.invite-btn {
    width: fit-content;
    align-self: flex-start;
}

.referral-card1 {
    width: 45%;
    background-color: white;
    padding: 0px 20px;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.referraltext-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 15px 0px;
}

.referraltext-wrapper button {
    padding: 10px 30px;
    background-color: var(--secondary-color);
    text-transform: capitalize;
    color: white;
    font-size: 1rem;
    border-radius: 10px;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.referraltext-wrapper button:hover {
    background-color: rgb(12, 70, 185);
}

.referraltext-wrapper h2 {
    font-size: 1rem;
    color: black;
    font-weight: 500;
    text-transform: capitalize;
    text-align: left;
}

.click-to-copy-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px solid rgb(178, 192, 253);
    border-radius: 5px;
    padding: 10px;
    background-color: rgb(247, 250, 255);
}

.clipboard-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    color: black;
    border: 1px solid rgb(203, 203, 203);
    border-radius: 2px;
    box-shadow: 0px 0px 1px rgb(216, 216, 216);
    cursor: pointer;
    width: 10%;
    margin: 3px;
    height: 100%;
    position: static;
}

.copied {
    color: green;
}

.click-to-copy-container input {
    font-size: .8rem;
    width: 90%;
    outline: none;
    background: rgb(247, 250, 255);
}

.referral-card1 p {
    width: 100%;
    text-align: left;
    color: rgb(72, 72, 72);
    font-size: .8rem;
}

.referral-card2 {
    width: 43%;
    background-color: white;
    padding: 10px 20px;
}

.bar4 {
    width: 100%;
    height: 70px;
    object-fit: contain;
}

.small-card {
    padding: 5px;
}

@media (max-width: 990px) and (min-width: 768px) {
    .kyc-card-wrapper {
        width: 100%;
    }

    .overview-container {
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .overview-card {
        margin-top: 30px;
        min-height: 200px;
    }

    .referral-card1 {
        padding: 10px;
    }

    .dashboardhomepagewrapper {
        width: 90%;
    }
}

@media screen and (max-width: 767px) {
    .kyc-card-wrapper {
        width: 100%;
    }

    .overview-container {
        flex-wrap: wrap;
        margin-top: 0px;
        flex-direction: column-reverse;
    }

    .overview-card {
        width: 100%;
    }

    .referral-card1 {
        padding: 10px;
        margin-bottom: 20px;
        width: 100%;
    }

    .referral-section {
        flex-direction: column;
    }

    .dashboardhomepagewrapper {
        width: 95%;
    }

    .overview-icon-container {
        width: 100%;
    }

    .dash-btn-container {
        width: 100%;
    }

    .overview-icon-container {
        height: 60px;
        width: 60px;
        border-radius: 50%;
        font-size: 1.5rem;
    }

    .overview-icon-wrapper small {
        font-size: 10px;
    }

    .dash-btn svg {
        display: none;
    }

    .dash-btn {
        margin: 5px;
    }
}