
.landpage{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: rgb(29, 46, 80);
    position: relative;
    padding-bottom: 100px;
    background-repeat: no-repeat;
}
.landpage-content-wrapper{
  position: relative;
}
.bg-svg{
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
}

.landpage-content-wrapper{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 70px;
    margin: auto;
}
.landpage-text-container{
    width: 45%;
    align-self: flex-start;
    text-align: left;
    padding-left: 20px;
}
.landpage-text-container h1{
    color:var(--primary-color);
    font-size: 2.2rem;
}
.landpage-text-container p{
    color: var(--mild-color);
    margin-top: 20px;
}
.landpage-img-container{
    width: 45%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.landpage-img-container .phone{
  width: 500px;
  height: 500px;
  object-fit: contain;
}
.floating-container-img1,.floating-container-img2,.floating-container-img3{
  position: absolute;
  background-color: var(--secondary-color);
  padding: 10px 30px;
  width: auto;
}
.floating-container-img1{
  object-fit: cover;
  height: auto;
  width: 280px;
  top:0px;
  right: 0px;
  font-size: 1rem;
}
.floating-container-img2{
  bottom: 0px;
  right: -80px;
}
.floating-container-img3{
  top: 50%;
  transform: translateY(-50%);
  height: 130px;
  left: -30px;
}
  @media (max-width: 990px) and (min-width: 768px){
    .landpage{
        justify-content: center;
    }
      .landpage-content-wrapper{
        flex-direction: column-reverse;
        justify-content: center;
        padding: 20px 0px;
      }
      .landpage-text-container{
        text-align: center;
        width: 95%;
        align-self: center;
        padding-left: 0px;
        text-align: left;
      }
      .launch-btn{
        margin: 30px 0px;
        align-self: flex-start;
      }
      .landpage-img-container{
        margin-bottom: 50px;
        width: 90%;
      }
      .floating-container-img3{
        right: 10px;
      }
  }
  @media screen and (max-width: 767px){
    .landpage{
      justify-content: center;
      padding-bottom: 0px;
  }
    .landpage-content-wrapper{
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
    .landpage-text-container{
      text-align: center;
      width: 85%;
      align-self: center;
      padding-left: 0px;
      text-align: left;
    }
    .landpage-text-container p{
      margin: 30px 0px;
      line-height: 22px;
    }
    .landpage-text-container h1{
      font-size: 1.9rem;
      color: white;
      width: 100%;
    }
    .launch-btn{
      margin: 40px 0px 0px 0px;
    }
    .bg-svg{
      display: none;
    }
    .landpage-img-container{
      width: 100%;
      margin-top: 40px;
    }
   
  }