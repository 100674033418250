.contact-section{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0px 20px 0px;
}
#contact{
    background-color: var(--secondary-color);
    width: 100%;
}
.contact-card{
    margin: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    height: 40px;
    padding: 0px ;
}
.contact-ball svg{
    margin: auto;
}
.contact-ball{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--mild-color);
    font-size: 1.1rem;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-right:15px;
}
.contact-card p{
    text-transform: capitalize;
    font-family: poppins;
    font-size: .9rem;
    font-weight: 400;
    margin: 0px;
    text-align: left;
    color: #b7b7b7;
}
.contact-card-text{
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contact-p h1{
    color: var(--mild-color);
}
.contact-p p{
    color: #b7b7b7;
}
.contact-form{
    width: 90%;
}
.contact-form-container{
width: 45%;
display: flex;
align-items: center;
justify-content: center;
align-self: flex-start;
}

@media screen and (max-width: 767px){
    .contact-form-container{
        width: 100% !important;
        margin-bottom: 0px;
        padding: 0px;
    }
    .contact-form{
        width: 100%;
    }
    .contact-section{
        padding-bottom: 0px;
    }
}