header {
    position: sticky;
    top: 0px;
    z-index: 4;
    background-color: transparent;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-self: flex-start;
    transition: all .5s ease-in-out;
    height: 60px;
}

.scroll-color {
    background-color: var(--secondary-mild);
    position: fixed;
    top: 0px;
}

nav {
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

nav ul {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

nav ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.line {
    height: 1.5px;
    width: 0px;
    transition: all .3s ease;
    background-color: var(--primary-color);
}

nav ul li:hover .line {
    width: 100%;
}

nav ul li a {
    color: white;
    text-transform: capitalize;
    font-size: .9rem;
}

.signup-btn {
    padding: 10px 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-variant);
    color: var(--primary-color);
    text-transform: capitalize;
    border-radius: 5rem;
    font-family: poppins;
    cursor: pointer;
    transition: all .5s ease-in-out;
    font-weight: 500;
    margin-top: 0px;
}

.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    overflow: hidden;
    padding-left: 75px;
}

.logo {
    object-fit: contain;
    cursor: pointer;
    width: 100%;
    align-self: center;
}

.txtlogo {
    height: 80px;
    object-fit: contain;
}

.mobile-menu-container {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 40px;
    cursor: pointer;
}

.line1,
.line2,
.line3 {
    width: 50%;
    height: 2px;
    background-color: rgb(175, 175, 175);
    margin: 3px 0px;
}

.mobile-menu-container {
    display: none;
}

.menu-modal {
    display: none;
}

.sign-up-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.overlay {
    display: none;
}

@media (max-width: 990px) and (min-width: 768px) {
    .logo-container {
        width: 30%;
        padding-left: 40px;
    }

    .menu-container {
        display: flex;
    }

    nav {
        display: none;
    }

    .sign-up-btn-container {
        display: none;

    }
}

@media screen and (max-width: 767px) {
    .overlay {
        display: block;
        width: 0%;
        height: 100vh;
        position: absolute;
        top: 0;
        transition: .2s all ease-in;
        left: 0;
    }

    .menu-modal {
        display: flex;
        position: absolute;
        width: 70%;
        height: 100%;
        overflow-x: hidden;
        background-color: white;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        top: 0;
        left: 0;
    }

    .showing-modal {
        width: 100vw;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .showing-modal ul li a {
        font-family: poppins, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .menu-modal ul {
        width: 80%;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        padding-top: 20px;
    }

    .menu-modal ul a {
        font-size: 1rem;
        color: #3e3e3e;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-transform: capitalize;
        margin: 5px 0px;
        padding: 10px;
        width: 100%;
        text-align: left;
    }

    .sign-up-btn-container {
        display: none;
    }

    .mobile-menu-container {
        display: flex;
    }

    nav {
        display: none;
    }

    .logo-container {
        width: 50%;
        padding-left: 10px;
        overflow-y: hidden;
    }

    .logo {
        margin-top: 5px;
        object-fit: contain;
    }

    header {
        height: 80px;
    }
}